import React from 'react';
import Button from '@material-ui/core/Button';
import styled from "styled-components"
type Props = {
    disabled: boolean;
    onClick: () => void;
    value: string
    variant?: "contained" | "outlined"
}

export const BasicButton: React.VFC<Props> = (props) => {
    const disabled = props.disabled ? props.disabled : false;
    const variant = props.variant;
    const onClick = props.onClick ? props.onClick : ()=>{};
    return(
        <StyledButton variant={variant ? variant : "contained"} color="primary" disabled={disabled} onClick={onClick} disableElevation>
            {props.value}
        </StyledButton>
    )
}

export const SmallButton = styled(Button).attrs({
    variant:"outlined",
    color:"primary"
})`
    margin:${props => props.theme.spacing(0.5)}px;
`;


const StyledButton = styled(Button)`
    width:calc(100% - ${props => props.theme.spacing(4)}px * 2);
    margin:${props => props.theme.spacing(4)}px 0;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        width:327px;
    }
    font-weight:bold;
`;
