import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { useTypedSelector } from '../../redux';

import LinearProgress from '@material-ui/core/LinearProgress';
import DevToolPage from './DevToolPage';

const TopPage = lazy(() => import('./TopPage'));
const LoginPage = lazy(() => import('./LoginPage'));
const OrganizationsPage = lazy(() => import('./OrganizationsPage'));
const EditOrganizationPage = lazy(() => import('./EditOrganizationPage'));
const AccountsPage = lazy(() => import('./AccountsPage'));
const EditAccountPage = lazy(() => import('./EditAccountPage'));
const MembersPage = lazy(() => import('./MembersPage'));
const OrdersPage = lazy(() => import('./OrdersPage'));
const NewsPage = lazy(() => import('./NewsPage'));
const EditNewsPage = lazy(() => import('./EditNewsPage'));
type Page = {
  path:string;
  component:JSX.Element;
  isPubulic:boolean;
}

const pages:Page[] = [
  { path: '/', component: <TopPage/> ,isPubulic:false},
  { path: '/login', component: <LoginPage/> ,isPubulic:true},
  { path: '/organizations', component: <OrganizationsPage/> ,isPubulic: false},
  { path: '/edit-organization', component: <EditOrganizationPage/> ,isPubulic: false},
  { path: '/accounts', component: <AccountsPage/> ,isPubulic: false},
  { path: '/members', component: <MembersPage/> ,isPubulic: false},
  { path: '/orders', component: <OrdersPage/> ,isPubulic: false},
  { path: '/create-account', component: <EditAccountPage/> ,isPubulic:false},
  { path: '/dev', component: <DevToolPage/> ,isPubulic:false},
  { path: '/news', component: <NewsPage/> ,isPubulic:false},
  { path: '/edit-news/:id', component: <EditNewsPage/> ,isPubulic:false},
  { path: '/create-news', component: <EditNewsPage/> ,isPubulic:false},
];

export const Pages = () => {
  const logined = useTypedSelector((state) => state.logined.isLogined);
  const checkAuth = (isPublic:boolean,page:any) => {
    if(isPublic){
      return page;
    }else{
      return logined ? page : <LoginPage/>;
    }
  }
  return (
    <>
      {pages.map((item, index) => (
        <Route
          key={index}
          exact
          path={item.path}
          component={() => <Suspense fallback={<LinearProgress />}>{checkAuth(item.isPubulic,item.component)}</Suspense>}
        />
      ))}
    </>
  );
};
