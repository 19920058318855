import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePickerView,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { SimpleGrid } from '../atoms/Grid';
import { TextField } from '@material-ui/core';
import dayjs from 'dayjs';
import styled from 'styled-components';

const timeRegex = /[0-9]{2}:[0-9]{2}/;
type Props = {
    value:  string | number | object | Date | null | undefined,
    required?: boolean,
    onChange?: (date: MaterialUiPickersDate, value?: string | null | undefined) => void,
    openTo: "date" | "year" | "month",
    label: string,
    views?: DatePickerView[],
    disabled?: boolean;
    format?: string;
    gridSize?: number;
    error?: boolean;
    helperText? : string;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> ;
    placeholder?: string;
}
type TimeProps = {
    value: string | number | null,
    defaultValue?: string | number,
    onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void,
    openTo: "hours" | "minutes" | "seconds",
    label: string,
    format?: string,
    gridSize?: number,
    disabled?: boolean,
    helperText? : string;
    error?: boolean;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> ;
    onClose?: () => void;
    required?: boolean;
}

const setLabelStyle =(error: boolean, disabled?: boolean) =>{
    if (error) return '#db3131'
    if (disabled) return '#A8A8A8'
    return "#000000"
  }

export const DatePicker: React.VFC<Props> = (props) => {
    const value = props.value;
    const required = props.required ? props.required : false;
    const onChange = props.onChange ? props.onChange : () => { };
    const views = props.views ? props.views : null;
    const format = props.format ? props.format : "yyyy/MM/dd";
    const disabled = props.disabled ? props.disabled : false;
    const gridSize = props.gridSize ? props.gridSize : 6;
    const placeholder = props?.placeholder ? props?.placeholder : '';
    return (
        <SimpleGrid item size={gridSize}>
        <Div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <StyledPicker
                    openTo={props.openTo}
                    placeholder={placeholder}
                    error={props.error}
                    helperText={props.helperText}
                    views={views ? views : ['year', 'month', 'date']}
                    margin="normal"
                    id="date-picker-dialog"
                    label={props.label}
                    format={format}
                    value={value ? value : null}
                    inputVariant={'outlined'}
                    onChange={onChange}
                    onBlur={props.onBlur}
                    required={required}
                    disabled={disabled}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    
                    InputLabelProps={{
                        style: {
                            fontWeight: 700,
                            color: setLabelStyle(props.error ? props.error : false, disabled),
                        },
                    }}
                />
            </MuiPickersUtilsProvider>
        </Div>
        </SimpleGrid>
    );
}

export const TimeViewPicker: React.VFC<TimeProps> = (props) => {
    const { value, error, } = props;
    const gridSize = props.gridSize ? props.gridSize : 6;

    const isStringTime = value ? timeRegex.test(value.toString()): false;
    const date = isStringTime ? new Date('2020-01-01T'+value) : value ? new Date(value) : new Date();
    const hhmm = value? dayjs(date).format("HH:mm") : "00:00";
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const input = e.currentTarget.value
        date.setHours(parseInt(input.substring(0, 2)));
        date.setMinutes(parseInt(input.substring(3)));
        props.onChange(date, e.currentTarget.value);
    }
    return (
        <SimpleGrid item size={gridSize}>
        <Div>
            <StyledTextField
                fullWidth
                disabled={props.disabled}
                helperText={props.helperText}
                margin="normal"
                id="date-picker-dialog"
                label={props.label}
                defaultValue={props.defaultValue}
                value={hhmm}
                onChange={(e) => onChange(e)}
                error={error}
                type="time"
                variant="outlined"
                InputLabelProps={{
                shrink: true,
                style: {
                    fontWeight: 700,
                    color: props.error ? '#f44336' : "#000",

                },
                }}
                required={props.required}
            />
        </Div>
        </SimpleGrid>
    );
}

const StyledPicker = styled(KeyboardDatePicker)`
    margin:${props => props.theme.spacing(2)}px 0 ;
    width: calc(100% - ${props => props.theme.spacing(1)}px);
    .MuiFormHelperText-root.Mui-error {
        color:#f44336
    };
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color:#f44336
    }
`;
const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
    margin:${props => props.theme.spacing(2)}px 0;
    width: calc(100% - ${props => props.theme.spacing(1)}px);
    .MuiFormHelperText-root.Mui-error {
        color:#f44336
    };
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color:#f44336
    };
    input[type="time"]::-webkit-calendar-picker-indicator {
        opacity: 0.54;
        width: 30px;
        background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M11.99%202C6.47%202%202%206.48%202%2012s4.47%2010%209.99%2010C17.52%2022%2022%2017.52%2022%2012S17.52%202%2011.99%202zM12%2020c-4.42%200-8-3.58-8-8s3.58-8%208-8%208%203.58%208%208-3.58%208-8%208z%22%2F%3E%3Cpath%20d%3D%22M12.5%207H11v6l5.25%203.15.75-1.23-4.5-2.67z%22%2F%3E%3C%2Fsvg%3E') center / contain no-repeat;
    }

`
