import React from 'react';
import Avatar from '@material-ui/core/Avatar';
//import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
//import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
//import { BasicButton } from './Button';
import Typography from '@material-ui/core/Typography';
import { Caption } from './Text';

type Props  = {
  name: string;
  caption: string;
  avatar: string;
  onClick: () => void;
}

export const CustomerAvatar: React.VFC<Props>= (props) => {
  const name = props.name;
  const caption = props.caption;
  const avatar = props.avatar;
  const onClick = props.onClick ? props.onClick : () => {};
  return (
    <RowWrap>
      <StyledAvatarSmall src={avatar} onClick={onClick} />
      <NameWrap>
        <Name>{name}</Name>
        <Caption>{caption}</Caption>
      </NameWrap>
    </RowWrap>
  );
};

const StyledAvatarSmall = styled(Avatar)`
  margin: ${(props) => props.theme.spacing(0)}px;
  height: 40px;
  width: 40px;
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    height: 80px;
    width: 80px;
    border-radius: 16px;
  }
`;

const RowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

const NameWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const Name = styled(Typography).attrs({
  variant: 'body1',
})`
  margin: ${(props) => props.theme.spacing(1)}px
    ${(props) => props.theme.spacing(2)}px 0;
  font-weight: 700;
`;

export const CircledAvatar = styled(Avatar)`
  margin: ${(props) => props.theme.spacing(1)}px;
`;

export const SmallAvatar = styled(Avatar)`
    width: ${props => props.theme.spacing(3)}px;
    height: ${props => props.theme.spacing(3)}px;
`
export const StyledCustomerAvatar= styled(CustomerAvatar)`
width: ${props => props.theme.spacing(3)}px;
height: ${props => props.theme.spacing(3)}px;
`
