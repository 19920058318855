const firebaseConfigProd = {
  apiKey: "AIzaSyCyg87-UD0Z-xzY7k0s7HtTdWAFnpCNIGc",
  authDomain: "coala-prod.firebaseapp.com",
  projectId: "coala-prod",
  storageBucket: "coala-prod.appspot.com",
  messagingSenderId: "61322356456",
  appId: "1:61322356456:web:a6a6a93401ca97d9450b5d",
  measurementId: "G-MELCBB7XC3"
};

const firebaseConfigStg = {
  apiKey: "AIzaSyBY_77Sv0FMIdHDOhhRqvV0SSRkeLJoUXg",
  authDomain: "coala-stg.firebaseapp.com",
  projectId: "coala-stg",
  storageBucket: "coala-stg.appspot.com",
  messagingSenderId: "1038658848206",
  appId: "1:1038658848206:web:6ce32cda63e43caec8179f",
  measurementId: "G-2YK81ZP28Q"
};
const firebaseConfigFeat = {
  apiKey: "AIzaSyBrdGvwBZPwWmbS973GTtnVKcTMXQLu-M4",
  authDomain: "rakuco-feat.firebaseapp.com",
  projectId: "rakuco-feat",
  storageBucket: "rakuco-feat.appspot.com",
  messagingSenderId: "955846324506",
  appId: "1:955846324506:web:ca39dc032e12edab0a3ca3",
  measurementId: "G-0DNTM1WW97"
};

export const firebaseConfig = process.env.REACT_APP_ENV === 'production' ? firebaseConfigProd :  process.env.REACT_APP_ENV === 'staging' ? firebaseConfigStg : firebaseConfigFeat;