import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';
import { firebaseConfig } from '../config';

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const functions = firebase.app().functions('asia-northeast1');
export const storageRef = firebase.storage().ref();
export const analytics =  firebase.analytics();
export const Timestamp = firebase.firestore.Timestamp;

if (!process.env.REACT_APP_ENV) {
  firestore.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099");
  functions.useEmulator("localhost", 5001);
  firebase.functions().useEmulator("localhost", 5001);
}