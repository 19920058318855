import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
html, body, div, span, iframe, h1, h2, h3, h4,
p, a, img, dl, dt, dd, ol, ul, li, label, table,
tbody, tr, th, td, figure{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
}
article, aside, figure, footer, header, nav, section {
    display: block;
}
body {
    line-height: 1.5;
    letter-spacing: 0.085em;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
ol, ul {
    list-style: none;
}
a {
  text-decoration:none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}`
