import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Organization } from 'common-types/firebase/firestore/organizations';

export type StateOrganization = Omit<Organization, 'createAt' | 'updateAt'> &
{
  createAtMillis: number,
  updateAtMillis: number,
  organizationId: string,
};
const initialState: StateOrganization[] = [{
  companyName: '',
  organizationCode: '',
  organizationName: '',
  serviceType: '',
  userCapacity: 0,
  representativeId: '',
  isGroupOrganization: false,
  isMaster: true,
  isMultifunctionalOrganization: false,
  totalUserCapacity: 0,
  postalCode: '',
  prefecture: '',
  city: '',
  town: '',
  areaCategory: '',
  areaCategory2024: '',
  cityCode: '',
  address: '',
  phoneNumber: '',
  aTypeExemption: {
    hasATypeExemption: false,
    charge: 0,
    unit: '%',
  },
  hasFoodService: false,
  pickUpService: {
    hasPickUpService: false,
    serviceType: '',
  },
  startPaymentDate: '',
  status: 'active',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  organizationId: ''
}];

const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrganizations: (_state: StateOrganization[], action: PayloadAction<StateOrganization[]>) => {
      return action.payload;
    },
    resetOrganizations: () => {
      return initialState;
    }
  }
});

export const { setOrganizations, resetOrganizations } = organizationSlice.actions;
export const organizationReducer = organizationSlice.reducer;