import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { SimpleGrid } from './Grid';
import styled from "styled-components"
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});

type Props = {
  title?: string,
  label?: string,
  value: number | string | boolean,
  helperText?: string,
  disabled?: boolean;
  required?: boolean,
  items: Array<any>,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  gridSize?: number,
  defaultValue?: number | string | boolean,
}

export const SelectInput: React.VFC<Props> = (props) => {
  const {  label, value, helperText, 
    disabled, required, items, onChange, gridSize, defaultValue } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    onChange(event)
  }

  return(
    <SimpleGrid item size={gridSize}>
      <Div>
          <ThemeProvider theme={formLabelsTheme}>
          <Field
            id="outlined-select-currency"
            variant="outlined"
            select
            label={label}
            value={value}
            defaultValue={defaultValue}
            onChange={handleChange}
            helperText={helperText}
            disabled={disabled}
            InputLabelProps={{
              style: {
                fontWeight: 700
              }
            }}
          >
            <MenuItem value="">
              <em>選択してください</em>
            </MenuItem>
            {items.map((item, index) => (
              <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
            ))}
          </Field>
        </ThemeProvider>
      </Div>
    </SimpleGrid>
  );
}

const Field = styled(TextField)`
  margin:${props => props.theme.spacing(2)}px 0;
  flex:1;
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset{
      border: solid 1px #B0B0B0;
    }
  }
  label.Mui-focused {
    color: #000000;
    font-weight: 700;
  };
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
    /* width:357px; */
  }
  width: 100%;
`;

const Div = styled.div`
  width: calc(100% - ${props => props.theme.spacing(1)}px);
  flex-wrap: wrap;
  @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  }
`;
