import { useEffect, useCallback } from 'react';
import { useAppDispatch } from '../redux';
import { auth, firestore } from './index';
import { login, logout } from '../redux/logined';
import { setAdminEmails, StateAdminEmail } from '../redux/adminEmails';
import { AdminEmails } from '../../../common-types/firebase/firestore/adminEmails';
import { setRoles, StateRole } from '../redux/roles';
import { Role } from '../../../common-types/firebase/firestore/roles';
import { newsConverter, setNews } from '../redux/news';
import { Organization } from '../../../common-types/firebase/firestore/organizations';
import { TypeBOrganization } from 'common-types/firebase/firestore/typeBOrganization';
import { TransferOrganization } from 'common-types/firebase/firestore/transferOrganization';
import { setOrganizations, StateOrganization } from '../redux/organizations';
import { setTypeBOrganization, StateTypeBOrganization } from '../redux/typeBOrganization';
import { StateTransferOrganization, setTransferOrganization } from '../redux/transferOrganization';

// type Work = {
//     id:string;
//     title:string;
//     photo:string;
//     url:string;
//   };

export const FirebaseToRedux = () => {
  const dispatch = useAppDispatch();

  const observeAdminEmails = useCallback(() => {
    const adminEmailsRef = firestore.collection('adminEmails').where('isDeleted','==', false);
    adminEmailsRef.onSnapshot((querySnapshot) => {
      const list: StateAdminEmail[] = [];
      querySnapshot.forEach((doc) => {
      const adminEmialSnapshot = doc.data() as AdminEmails;
        list.push({
          createdBy: adminEmialSnapshot.createdBy,
          updatedBy: adminEmialSnapshot.updatedBy,
          isDeleted: false,
          email: adminEmialSnapshot.email,
          createAtMillis: adminEmialSnapshot.createAt ?  adminEmialSnapshot.createAt.toMillis() : 0,
          updateAtMillis: adminEmialSnapshot.updateAt? adminEmialSnapshot.updateAt.toMillis() : 0,
        });
      });
      dispatch(setAdminEmails(list));
    });
  },[dispatch]);

  const observeOrganizations = useCallback(() => {
    const organizationsRef = firestore.collection('organizations');
    organizationsRef.onSnapshot((querySnapshot) => {
      const list: StateOrganization[] = [];
      querySnapshot.forEach((doc) => {
      const organizationSnapshot = doc.data() as Organization;
      const {
        createAt,
        updateAt,
        ...params
      } = organizationSnapshot
        list.push({
          ...params,
          createAtMillis: createAt.toMillis(),
          updateAtMillis: updateAt.toMillis(),
          organizationId: doc.id
        });
      });
      dispatch(setOrganizations(list));
    });
  }, [dispatch]);

  const observeTypeBOrganizations = useCallback(() => {
    const organizationsRef = firestore.collection('typeBOrganizations');
    organizationsRef.onSnapshot((querySnapshot) => {
      const list: StateTypeBOrganization[] = [];
      querySnapshot.forEach((doc) => {
      const organizationSnapshot = doc.data() as TypeBOrganization;
      const {
        createAt,
        updateAt,
        ...params
      } = organizationSnapshot
        list.push({
          ...params,
          createAtMillis: createAt.toMillis(),
          updateAtMillis: updateAt.toMillis(),
          organizationId: doc.id
        });
      });
      dispatch(setTypeBOrganization(list));
    });
  }, [dispatch]);

  const observeTransferOrganizations = useCallback(() => {
    const organizationsRef = firestore.collection('transferOrganizations');
    organizationsRef.onSnapshot((querySnapshot) => {
      const list: StateTransferOrganization[] = [];
      querySnapshot.forEach((doc) => {
      const organizationSnapshot = doc.data() as TransferOrganization;
      const {
        createAt,
        updateAt,
        ...params
      } = organizationSnapshot
        list.push({
          ...params,
          createAtMillis: createAt.toMillis(),
          updateAtMillis: updateAt.toMillis(),
          organizationId: doc.id
        });
      });
      dispatch(setTransferOrganization(list));
    });
  }, [dispatch]);

  const observeRoles = useCallback(() => {
    firestore.collection('roles').where('isDeleted','==', false).onSnapshot((querySnapshot) => {
      const list: StateRole[] = [];
      querySnapshot.forEach(async (doc) => {
        if(doc.exists) {
        const roleSnapshot = doc.data() as Role;
        list.push({
          types: roleSnapshot.types,
          organizationIds: roleSnapshot.organizationIds,
          email: roleSnapshot.email,
          roleId: doc.id,
          createAtMillis: roleSnapshot.createAt ? roleSnapshot.createAt.toMillis() : 0,
          updateAtMillis: roleSnapshot.updateAt ? roleSnapshot.updateAt.toMillis() : 0,
          createdBy: roleSnapshot.createdBy,
          updatedBy: roleSnapshot.updatedBy,
          isDeleted: roleSnapshot.isDeleted,
          initialName: roleSnapshot.initialName? roleSnapshot.initialName : '',
          userId: roleSnapshot.userId,
        });
      }
      });
      dispatch(setRoles(list));
    });
  }, [dispatch]);

  const observeNews = useCallback(() => {
    firestore.collection('news').where('isDeleted', '==', false).orderBy('updateAt','desc').withConverter(newsConverter).onSnapshot(async (querySnapshot) => {
      const snapshot = querySnapshot.docs;
      dispatch(setNews(snapshot.map(doc => doc.data())));
    });
  },[dispatch]);

  


  const observeAuth = useCallback(() => {
    auth.onAuthStateChanged(async(user) => {
      if (user && user.email) {
        dispatch(login({isLogined: true, email: user.email, uid: user.uid}));
        observeAdminEmails();
        observeOrganizations();
        observeTypeBOrganizations();
        observeTransferOrganizations();
        observeRoles();
        observeNews();
      } else {
        dispatch(logout({isLogined: false, email: "", uid: ''}));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    observeAuth();
  }, [observeAuth]);


  return null;
}
