import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createSelectorHook,useDispatch } from 'react-redux';

import { loginedReducer } from './logined';
import { adminEmailsReducer } from './adminEmails';
import { organizationReducer } from './organizations';
import { rolesReducer } from './roles';
import { newsReducer } from './news';
import { typeBOrganizationReducer } from './typeBOrganization';
import { transferOrganizationReducer } from './transferOrganization';

const reducer = combineReducers({
  logined:loginedReducer,
  adminEmails: adminEmailsReducer,
  organizations: organizationReducer,
  roles: rolesReducer,
  news: newsReducer,
  typeBOrganizations: typeBOrganizationReducer,
  transferOrganizations: transferOrganizationReducer
});

export const store = configureStore({
  reducer
});
export type RootState = ReturnType<typeof store.getState>;

export const useTypedSelector = createSelectorHook<RootState>();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
