import React from 'react';
import Grid from '@material-ui/core/Grid';

export const SimpleGrid = (props: any) => {
  return (
        <Grid item xs={props.size ? props.size : 12}>
            {props.children}
        </Grid>
  );
}
