import React from "react";
import styled, { keyframes } from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import { useTypedSelector } from "../../redux";

const fadeIn = keyframes`
    0% {
      opacity:0.1;
    }
    100% {
      opacity:1;
    }
`;

const AppName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(props) => props.theme.gutter.sp};
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    height: ${(props) => props.theme.gutter.pc};
  }
`;

const DrawerArea = styled.div`
  width: ${(props) => props.theme.drawerWidth};
  background: ${(props) => props.theme.palette.primary.dark};
  color: white;
  height: 100%;
`;

const StyledDrawer = styled(Drawer)`
  background-color: red;
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.2s ease-in-out;
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${(props) => props.theme.zIndex.drawer};
`;
const Content = styled.div`
  padding-top: calc(${(props) => props.theme.spacing(6)}px);
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    max-width: calc(100% - ${(props) => props.theme.drawerWidth});
    margin-left: ${(props) => props.theme.drawerWidth};
    padding-top: ${(props) => props.theme.spacing(7)}px;
    background: #fafafa;
    min-height: 100vh;
  }
`;
const PublicContent = styled.div`
  padding-top: calc(${(props) => props.theme.spacing(6)}px);
  @media screen and (min-width: ${(props) =>
      props.theme.breakpoints.values.sm}px) {
    max-width: 100%;
    padding-top: ${(props) => props.theme.spacing(7)}px;
    background: #fafafa;
    min-height: 100vh;
  }
`;

type AppFrameProps = {
  appLogo: React.ReactNode;
  menus: any;
  children: React.ReactNode
}

export const AppFrame: React.VFC<AppFrameProps> = (props) => {
  const logined = useTypedSelector((state) => state.logined);
  const [open, setOpen] = React.useState(false);
  const handleMenu = () => {
    setOpen(!open);
  };
  const openManual = () => {
    window.open('https://yahoo.co.jp', "_blank");
  };
  return (
    <>
      <Hidden smUp>
        {open ? (
          <Overlay onClick={handleMenu}>
            <StyledDrawer variant="permanent" open>
              <DrawerArea>
                <AppName>{props.appLogo}</AppName>
                {props.menus}
              </DrawerArea>
            </StyledDrawer>
          </Overlay>
        ) : null}
      </Hidden>
      {logined?.isLogined && (
        <Hidden xsDown>
          <StyledDrawer variant="permanent" open>
            <DrawerArea>
              <AppName>{props.appLogo}</AppName>
              {props.menus}
            </DrawerArea>
          </StyledDrawer>
        </Hidden>
      )}
      {logined?.isLogined ?
      <Content>{props.children}</Content>
      : <PublicContent>{props.children}</PublicContent>
      }
    </>
  );
};

