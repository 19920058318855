import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from 'common-types/firebase/firestore/roles/';

export type StateRole = Omit<Role, 'createAt' | 'updateAt'> & {roleId: string, createAtMillis: number, updateAtMillis: number};

export const initialState: StateRole[] = [{
  types: [{
    organizationId: '',
    type: '',
    serviceType: '',
  }],
  organizationIds: [],
  email: '',
  roleId: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  initialName: '',
  userId: '',
}];

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (_state: StateRole[], action: PayloadAction<StateRole[]>) => {
      return action.payload;
    },
    resetRoles: () => {
      return initialState;
    }
  }
});

export const { setRoles, resetRoles } = rolesSlice.actions;
export const rolesReducer = rolesSlice.reducer;
