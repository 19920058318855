const specialStringRegex = /[-/\\^$*+?.()|[\]{}]/g;
const katakanaRegex = /^([\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}])+$/mu;
const urlRegex = /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
const emailRegex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/i;
const phoneNumberRegex = /^[0-9]{10,11}$/;
const patientNumberRegex = /^\d+$/;
const priceRegex = /^([1-9]\d*|0)$/;
const bankAccountNumberRegex = /^\d{7}$/;
const branchCodeRegex = /^\d{3}$/;
const postalCodeRegex = /^(\d{7}|\d{8})$/
const address_2Regex = /^[0-9-]+$/;
const onChangeCatchCopyRegex = /^.{0,30}$/;
const symbolRegex = /[!"#$%&'()☆＊※@☆*+\-.,/:;<=>?@＠[\\\]^_`{|}~【】￥「」]/g;
const percentRegex = /^[1-9][0-9]?$/;
const organizationCodeRegex = /^[0-9]{10}$/;
export const naturalNumberRegex = /^\d+$/;
const areaCategoryRegex = /^[0-9]{1}$/;
const cityCodeRegex = /^[0-9]{5}$/;

export const isMatchValidation = (name: string, data: any) => {
  if (data !== '') {
    switch (name) {
      case 'name':
      case 'title':
      case 'nickName':
      case 'lastNameKanji':
      case 'firstNameKanji':
      case 'occupation': {
        if (!specialStringRegex.test(data)) {
          return true;
        } else {
          return false
        }
      }
      case 'katakana':
      case 'lastNameKana':
      case 'firstNameKana':
      case 'bankAccountHolder': {
        if (katakanaRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'webSite': {
        if (urlRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'email': {
        if (emailRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'phoneNumber': {
        if (phoneNumberRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'patientNumber': {
        if (patientNumberRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'regularPrice':
      case 'memberPrice':
      case 'membershipPrice':
      case 'backNumberPrice':
      case 'forFreeUserPrice':
      case 'forSubscriberPrice':
      case 'defaultStock': {
        if (priceRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'bankAccountNumber': {
        if (bankAccountNumberRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'branchCode': {
        if (branchCodeRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'postalCode': {
        if (postalCodeRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'address_2': {
        if (address_2Regex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'onChangeCatchCopy': {
        if (onChangeCatchCopyRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'symbol': {
        if (symbolRegex.test(data)) {
          return true;
        }
        return false;
      }
      case 'percent': {
        if (percentRegex.test(data)) {
          return true;
        } else {
          return false;
        }
      }
      case 'organizationCode': {
        if (organizationCodeRegex.test(data)) {
          return true;
        }
        return false;
      }
      case 'naturalNumber':
      case 'unitTime': {
        if (naturalNumberRegex.test(data)) {
          return true;
        }
        return false;
      }
      case 'areaCategory': {
        if (areaCategoryRegex.test(data)) {
          return true;
        }
        return false;
      }
      case 'cityCode': {
        if (cityCodeRegex.test(data)) {
          return true;
        }
        return false;
      }
      default: {
        break;
      }
    }
  } else {
    return false;
  }
  return false;
};