import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type State = {
  isLogined: boolean;
  email: string;
  uid: string;
}

const loginedSlice = createSlice({
  name: 'logined',
  initialState: {
    isLogined: false,
    email: "",
    uid: "",
  },

  reducers: {
    login: (_state, action: PayloadAction<State>) => ({
        isLogined: true,
        email: action.payload.email,
        uid: action.payload.uid
    }),
    logout: (_state, action: PayloadAction<State>) => {
      return {
        isLogined: false,
        email: '',
        uid: '',
      };
    }
  }
});

export const { login, logout } = loginedSlice.actions;
export const loginedReducer = loginedSlice.reducer;
