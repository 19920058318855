import React, { useEffect, useState } from 'react';
//import { useSelector } from 'react-redux';
//import IconButton from '@material-ui/core/IconButton';
import { CircledAvatar } from '../atoms/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { HalfPaper, QuarterPaper } from '../atoms/Paper';
import { logout } from '../../firebase/auth';
import { RacukoIcon, RacukoTitle } from '../../icons';
import { Typography } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';

import { useTypedSelector } from '../../redux';

type IconMenuProps = {}
type HeaderSubMenuProps = {
  anchorEl:null | HTMLElement,
  subMenus:Array<{label: string, path: string}>| null,
  open: boolean,
  handleClose: () => void
}

export const MenuList = (props: any) => {
  const logined = useTypedSelector((state) => state.logined);
  const menus = props.menus;
  const [menusList, setMenusList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const history = useHistory();
  const moveToPage = (item: any, index: any) => {
    if (item.text === 'ログアウト') {
      logout();
    } else if (item.path.match(/http/)) {
      window.alert('外部のサイトが起動します');
      window.open(item.path, '_blank');
    } else {
      history.push(item.path);
      setSelectedIndex(index)
    }
  };
  const getMenusData = async () => {
    setMenusList(menus(logined ? logined : false))
  }
  useEffect(() => {
    getMenusData();
  }, []);
  return (
    <List component="nav" aria-label="main mailbox folders">
      {menusList ?
        menusList.length > 0 ?
        menusList.map((item: any, index: any) => (
            <StyledItem onClick={() => moveToPage(item, index)} key={index} button selected={index === selectedIndex}>
              <ListItemIcon >{item.icon}</ListItemIcon>
              <BoldText primary={item.text} />
            </StyledItem>
          )) : null
        : null}
    </List>
  );
};

export const IconMenu: React.VFC<IconMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickLogout = () => {
    logout();
    history.push('/');
    setAnchorEl(null);
  }
  return (
    <StyledDiv>
      <CircledAvatar
        src={''}
        onClick={(e) => handleClick(e)}
      />
       <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style:{display: 'flex'}
        }}
        style={{display: 'flex', marginTop: 14}}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
      <MenuItem　onClick={onClickLogout}>ログアウト</MenuItem>
      </Menu>
      <Typography style={{fontWeight:'bold', fontSize: 13}}>{`admin（Curip株式会社）`}</Typography>
    </StyledDiv>
  );
};

export const HeaderTitleLogo = () => {
  return(
    <Div>
    <RacukoIcon/>
    <div style={{marginLeft:13}}>
    <RacukoTitle/>
    </div>
    </Div>
  )
}

export const HeaderSubMenus: React.VFC<HeaderSubMenuProps> = (props) => {
  const history = useHistory();
  const anchorEl = props.anchorEl;
  const open = props.open;
  const subMenus = props.subMenus;
  const handleClose = props.handleClose;
  return(
    <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{
      'aria-labelledby': 'basic-button',
      style:{
        display: 'flex',
        padding: '36px 32px',
        flexWrap: 'wrap',
      }
    }}
    style={{display: 'flex', marginTop: 14}}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
  >
    {subMenus && subMenus.map((subMenu) => {
      const handleSubMenu = () => {
        history.push(subMenu.path);
        handleClose()
      }
    return(
      <>
      <Badge color={'error'} badgeContent=" " variant="dot">
      <MenuItem 
        onClick={handleSubMenu}>
          {subMenu.label}
      </MenuItem>
      </Badge>
      </>
    )
  })}
  </Menu>
  )
}


const BoldText = styled(ListItemText)`
  > * {
    font-size:14px;
    font-weight:bold;
  }
`
const Div = styled.div`
display:flex;
align-items: flex-end;
`;

const StyledDiv = styled(Div)`
align-items: center;
`



const StyledItem = styled(ListItem)`

`;

/*const Text = styled(ListItemText)`
  color:white;
  > * {
    font-size:14px;
    font-weight:bold;
  }
`

const Icon = styled(ListItemIcon)`
  color:white;
  background: white
`*/
