import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { News } from 'common-types/firebase/firestore/news';
import firebase from 'firebase';

export type StateNews = Omit<News, 'createAt' | 'updateAt'> & {newsId: string, createAtMillis: number, updateAtMillis: number};

export const initialState: StateNews = {
  title: '',
  description:'',
  isExternalSource: true,
  content: '',
  url: '',
  isPublish: false,
  category: [],
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  newsId: '',
};

const newsSlice = createSlice({
  name: 'news',
  initialState: [initialState],
  reducers: {
    setNews: (_state: StateNews[], action: PayloadAction<StateNews[]>) => {
      return action.payload;
    },
    resetNews: () => {
      return [initialState];
    }
  }
});

export const { setNews, resetNews } = newsSlice.actions;
export const newsReducer = newsSlice.reducer;

export const newsConverter = {
  toFirestore(news: StateNews): firebase.firestore.DocumentData {
    let newsData = {
      title: news.title,
      description: news.description,
      isExternalSource: news.isExternalSource,
      content: news.content,
      url: news.url,
      isPublish: news.isPublish,
      category: news.category,
      createAt: news.createAtMillis ? firebase.firestore.Timestamp.fromMillis(news.createAtMillis) : firebase.firestore.Timestamp.now(),
      updateAt: firebase.firestore.Timestamp.now(),
      createdBy: news.createdBy,
      updatedBy: news.updatedBy,
      isDeleted: news.isDeleted
    } as firebase.firestore.DocumentData;
    return newsData;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StateNews {
    const data = snapshot.data(options)!;
    const newsData = {
      title: data.title,
      description: data.description,
      isExternalSource: data.isExternalSource,
      content: data.content,
      url: data.url,
      isPublish: data.isPublish,
      category: data.category,
      createAtMillis: data.createAt ? data.createAt.toMillis() : null,
      updateAtMillis: data.updateAt ? data.updateAt.toMillis() : null,
      createdBy: data.createdBy,
      updatedBy: data.updatedBy,
      isDeleted: data.isDeleted,
      newsId: snapshot.id
    };
    return newsData;
  }
}