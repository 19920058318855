import { BodyTitle } from "../atoms/Text";
import styled from 'styled-components';
import { DevArea } from "../organisms/DevArea";


const DevToolPage = () =>{
  return(
    <div>
    <StyledBodyTitle>{'開発ツールページ'}</StyledBodyTitle>
      <DevArea/>
    </div>
  )
}
export default DevToolPage;

const StyledBodyTitle = styled(BodyTitle)`
margin: 0 ${props => props.theme.spacing(2)}px;
`;