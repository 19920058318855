import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminEmails } from 'common-types/firebase/firestore/adminEmails';

export type StateAdminEmail= Omit<AdminEmails, 'createAt' | 'updateAt'> &
{
  createAtMillis: number,
  updateAtMillis: number
};
const initialState: StateAdminEmail[] = [{
  email: '',
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  createAtMillis: 0,
  updateAtMillis: 0
}];

const adminEmailsSlice = createSlice({
  name: 'adminEmails',
  initialState,
  reducers: {
    setAdminEmails: (_state: StateAdminEmail[], action: PayloadAction<StateAdminEmail[]>) => {
      return action.payload;
    },
    resetAdminEmails: () => {
      return initialState;
    }
  }
});

export const { setAdminEmails, resetAdminEmails } = adminEmailsSlice.actions;
export const adminEmailsReducer = adminEmailsSlice.reducer;