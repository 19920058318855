import './firebase/index'
import { ThemeProvider as MaterialThemeProvider,StylesProvider } from "@material-ui/styles";
import { Provider } from 'react-redux';
import { store, useTypedSelector } from './redux';
import  { Pages } from './components/pages';
import { FirebaseToRedux } from './firebase/FirebaseToRedux';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyle } from './styles/GlobalStyle';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AppFrame } from './components/templates/AppFrame';
import { MenuList } from './components/molecules/MenuList';
import { AppLogo } from './components/atoms/AppLogo';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import NotificationsIcon from '@mui/icons-material/Notifications';

const menus = () => {
  return [
     {
      path: `/`,
      icon: <CorporateFareIcon style={{color: 'white' , width: 30 , height: 30}}/>,
      text: 'ダッシュボード',
    },
    {
      path: `/organizations`,
      icon: <CorporateFareIcon style={{color: 'white' , width: 30 , height: 30}}/>,
      text: '事業所一覧',
    },
    {
      path: `/accounts`,
      icon: <PeopleAltIcon style={{color: 'white' , width: 30 , height: 30}}/>,
      text: '管理者一覧',
    },
    {
      path: `/members`,
      icon: <PeopleAltIcon style={{color: 'white' , width: 30 , height: 30}}/>,
      text: '職員一覧',
    },
    {
      path: `/news`,
      icon: <NotificationsIcon style={{color: 'white' , width: 25 , height: 25}}/>,
      text: 'お知らせ設定',
    },
    {
      path: `/orders`,
      icon: <LocalAtmIcon style={{color: 'white' , width: 30 , height: 30}}/>,
      text: '決済情報一覧',
    },
    {
      path: `/dev`,
      icon: <SettingsIcon style={{color: 'white' , width: 30 , height: 30}}/>,
      text: '開発ツール',
    },
    {
      path: `/logout`,
      icon: <LogoutIcon style={{color: 'white' , width: 30 , height: 30}}/>,
      text: 'ログアウト',
    },
  ];
};

const App = () => { 
  return (
    <StylesProvider injectFirst>
    <MaterialThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <HelmetProvider>
      <Provider store={store}>
        <FirebaseToRedux />
        <Router>
        <AppFrame
          appLogo={<AppLogo/>}
          menus={<MenuList menus={menus} />}
        >
        <Pages />
        </AppFrame>
        </Router>
      </Provider>
      </HelmetProvider>
    </ThemeProvider>
    </MaterialThemeProvider>
    </StylesProvider>
  );
};

export default App;