import React, { useEffect, useState } from 'react';
import { SimpleGrid } from '../atoms/Grid';
import { makeStyles, ThemeProvider, createTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Avatar from '@material-ui/core/Avatar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { isMatchValidation } from '../../utils/Validation';
import Typography from '@material-ui/core/Typography';

const smWidth = '375px';
const smTop = '45px';

type TextFieldProps = {
  setFunction?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  name?: string;
  label?: string;
  value: string | number;
  validationType?: string;
  required?: boolean;
  propsHelperMessage?: string;
  readOnly?: boolean;
  placeholder?: string;
  helperText1?: string;
  helperText2?: string;
  maxLength?: string;
  rows?: number;
  gridSize?: number;
  disabled?: boolean;
  title?: string;
  type?: string;
  multiline?: boolean;
  isError?: boolean;
  onBlurProps?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: '5px'
  },
  helperText: {
    color: 'red',
    marginLeft:0
  },
  photo: {
    cursor: 'pointer',
    color: '#BABAB9',
  },
  doneIcon: {
    color: '#27AE60'
  },
  errorIcon: {
    color: '#DC3545'
  },
  cancelIcon: {
    cursor: 'pointer',
    color: '#DADADA'
  },
}));

const formLabelsTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131"
        }
      }
    }
  }
});

const setLabelStyle =(error: boolean, disabled?: boolean) =>{
  if (error) return '#db3131'
  if (disabled) return '#A8A8A8'
  return "#000000"
}


export const SingleTextInput = (props: TextFieldProps) => {
  const classes = useStyles();
  const {
    setFunction,
    name,
    label,
    value,
    required,
    validationType,
    propsHelperMessage,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxLength,
    gridSize,
    disabled,
    type,
    rows,
    multiline,
    isError,
    onBlurProps
  } = props;
  const [helperMessage, setHelperMessage] = useState('');
  const [error, setError] = useState(false);
  const [valueLength, setValueLength] = useState(value ? String(value).length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (setFunction) {
      let data = e.target.value;
      if (validationType) {
        if (!isMatchValidation(validationType || '', data)) {
          setHelperMessage(propsHelperMessage || '');
          setError(true);
        } else {
          setHelperMessage('');
          setError(false);
        }
      }
      setFunction(data);
      setValueLength(data?.length);
    }
    if (props.onChange) {
      props.onChange(e)
    }
  };
  const clearText = () => {
    if (setFunction) {
      // 文字列クリアの処理

      setFunction( "");
      // 文字クリア後に自身のフィールドへ自動フォーカスさせる
      const field: any = document.querySelector(`input[name=${name}]`);
      if (field) {
        field.focus();
      }
    }
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    setValueLength(value ? String(value).length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <SimpleGrid item size={gridSize}>
      <Div>
        <ThemeProvider theme={formLabelsTheme}>
          <StyledForm>
            <StyledTextField
              name={name}
              onFocus={onFocus}
              onBlur={onBlurProps ? onBlurProps : onBlur}
              error={isError ? isError : error}
              type={type}
              classes={{ root: classes.root }}
              placeholder={placeholder}
              onChange={onChange}
              label={label}
              variant="outlined"
              value={value}
              required={required}
              helperText={(!validationType && isError) ? propsHelperMessage : helperMessage}
              disabled={disabled}
              rows={rows}
              multiline={multiline}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: setLabelStyle(error, disabled),
                  fontWeight: 700
                },
              }}
              InputProps={{
                readOnly: readOnly,
                inputProps: {
                  max: 100, min: 0
               },
                endAdornment:
                type !== 'number' ?
                  !readOnly ? <InputAdornment position="end" disablePointerEvents={false}>
                    {valueLength === 0 ?
                      // 空の時は何も表示しない
                      <></> :
                      error ?
                        // エラーの時
                        <ErrorIcon
                          className={classes.errorIcon}
                        /> :
                        // 正常な文字列の時
                        inputEvent ?
                          // 入力中
                          <HighlightOffIcon
                            className={classes.cancelIcon}
                            onClick={() => clearText()}
                          />
                          :
                          // 入力中でない時
                          <DoneIcon
                            className={classes.doneIcon}
                          />}
                  </InputAdornment> :
                    null : null,
              }}
              inputProps={{ maxLength: maxLength }}
            />
            <HelperWrap>
              {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
              {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
            </HelperWrap>
          </StyledForm>
        </ThemeProvider>
      </Div>
    </SimpleGrid>
  );
}

export const SingleTextPasswdInput = (props: TextFieldProps) => {
  const classes = useStyles();
  const {
    setFunction,
    name,
    value,
    required,
    validationType,
    propsHelperMessage,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxLength,
    disabled,
  } = props;
  const [helperMessage, setHelperMessage] = useState('');
  const [error, setError] = useState(false);
  //const [valueLength, setValueLength] = useState(value ? value.length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const [passwdValues, setPasswdValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  const onChange = (e: any) => {
    if (setFunction) {
      let data = e.target.value;
      if (validationType) {
        if (!isMatchValidation(validationType || '', data)) {
          setHelperMessage(propsHelperMessage || '');
          setError(true);
        } else {
          setHelperMessage('');
          setError(false);
        }
      }
      setFunction(data);
      //setValueLength(data?.length);
    }
  };
  const handleClickShowPassword = () => {
    setPasswdValues({ ...passwdValues, showPassword: !passwdValues.showPassword });
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    //setValueLength(value ? value.length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <SimpleGrid item xs={props.gridSize}>
      <Div>
        <ThemeProvider theme={formLabelsTheme}>
          <StyledForm>
            <TextField
              name={name}
              onFocus={onFocus}
              onBlur={onBlur}
              error={error}
              classes={{ root: classes.root }}
              placeholder={placeholder}
              onChange={onChange}
              label={name}
              variant="outlined"
              value={value}
              required={required}
              helperText={helperMessage}
              disabled={disabled}
              type={passwdValues?.showPassword ? 'text' : 'password'}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: setLabelStyle(error, disabled),
               
                },
              }}
              InputProps={{
                readOnly: readOnly,
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      style={{padding: 0}}
                    >
                      {passwdValues?.showPassword ? <Visibility  style={{padding: 0}}/> : <VisibilityOff style={{padding: 0}}/>}
                    </IconButton>
                  </InputAdornment>,
              }}
              inputProps={{ maxLength: maxLength }}
            />
            <HelperWrap>
              {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
              {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
            </HelperWrap>
          </StyledForm>
        </ThemeProvider>
      </Div>
    </SimpleGrid>
  );
}
export const MultiLineTextInput = (props: TextFieldProps) => {
  const classes = useStyles();
  const rows = props.rows ? props.rows : 5;
  const {
    setFunction,
    name,
    value,
    required,
    validationType,
    propsHelperMessage,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxLength,
    label,
    isError,
    onBlurProps
  } = props;
  const [helperMessage, setHelperMessage] = useState('');
  const [error, setError] = useState(false);
  const [valueLength, setValueLength] = useState(value ? String(value).length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const onChange = (e: any) => {
    if (setFunction) {
      let data = e.target.value;
      if (validationType) {
        if (!isMatchValidation(validationType || '', data)) {
          setHelperMessage(propsHelperMessage || '');
          setError(true);
        } else {
          setHelperMessage('');
          setError(false);
        }
      }
      setFunction(data);
      setValueLength(data?.length);
    }
  };
  const clearText = () => {
    if (setFunction) {
      // 文字列クリアの処理
      setFunction("");
      // 文字クリア後に自身のフィールドへ自動フォーカスさせる
      const field: any = document.querySelector(`input[name=${name}]`);
      if (field) {
        field.focus();
      }
    }
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    setValueLength(value ? String(value).length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <SimpleGrid item size={props.gridSize}>
      <Div>
        <ThemeProvider theme={formLabelsTheme}>
          <StyledForm>
            <TextField
              name={name}
              onFocus={onFocus}
              onBlur={onBlurProps ? onBlurProps : onBlur}
              error={isError ? isError : error}
              classes={{ root: classes.root }}
              placeholder={placeholder}
              onChange={onChange}
              label={label}
              multiline
              rows={rows}
              variant="outlined"
              value={value}
              required={required}
              helperText={(!validationType && isError) ? propsHelperMessage : helperMessage}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: setLabelStyle(error),
                  fontWeight: 700
                },
              }}
              InputProps={{
                readOnly: readOnly,
                endAdornment:
                  <InputAdornment position="end" disablePointerEvents={false}>
                    {valueLength === 0 ?
                      // 空の時は何も表示しない
                      <></> :
                      error ?
                        // エラーの時
                        <StyledErrorIcon
                          className={classes.errorIcon}
                        /> :
                        // 正常な文字列の時
                        inputEvent ?
                          // 入力中
                          <StyledHighlightOffIcon
                            className={classes.cancelIcon}
                            onClick={() => clearText()}
                          />
                          :
                          // 入力中でない時
                          <StyledDoneIcon
                            className={classes.doneIcon}
                          />}
                  </InputAdornment>,
              }}
              inputProps={{ maxLength: maxLength }}
            />
            <HelperWrap>
              {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
              {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
            </HelperWrap>
          </StyledForm>
        </ThemeProvider>
      </Div>
    </SimpleGrid>
  );
}

export const SingleFullTextInput = (props: TextFieldProps) => {
  const classes = useStyles();
  const {
    setFunction,
    name,
    label,
    value,
    required,
    validationType,
    propsHelperMessage,
    readOnly,
    placeholder,
    helperText1,
    helperText2,
    maxLength,
    gridSize,
    disabled,
    title,
    type
  } = props;
  const [helperMessage, setHelperMessage] = useState('');
  const [error, setError] = useState(false);
  const [valueLength, setValueLength] = useState(value ? String(value).length : 0);
  const [inputEvent, setInputEvent] = useState(false);
  const onChange = (e: any) => {
    if (setFunction) {
      let data = e.target.value;
      if (validationType) {
        if (!isMatchValidation(validationType || '', data)) {
          setHelperMessage(propsHelperMessage || '');
          setError(true);
        } else {
          setHelperMessage('');
          setError(false);
        }
      }
      setFunction(data);
      setValueLength(data?.length);
    }
  };
  const clearText = () => {
    if (setFunction) {
      // 文字列クリアの処理

      setFunction( "");
      // 文字クリア後に自身のフィールドへ自動フォーカスさせる
      const field: any = document.querySelector(`input[name=${name}]`);
      if (field) {
        field.focus();
      }
    }
  };
  const onFocus = () => {
    setInputEvent(true);
  };
  const onBlur = () => {
    // フォーカス解除を少し遅らせることで、
    // clearText関数の実行を待つ
    setTimeout((() => {
      setInputEvent(false);
    }), 200);
  };
  useEffect(() => {
    setHelperMessage(helperMessage);
    setValueLength(value ? String(value).length : 0);
  }, [helperMessage, value, inputEvent]);
  return (
    <SimpleGrid item size={gridSize}>
      <Div>
      {title && <Title data-required={required} variant="body1" gutterBottom>{title}</Title>}
      <ThemeProvider theme={formLabelsTheme}>
          <StyledForm>
            <StyledTextField
              name={name}
              onFocus={onFocus}
              onBlur={onBlur}
              error={error}
              type={type}
              classes={{ root: classes.root }}
              placeholder={placeholder}
              onChange={onChange}
              label={label}
              variant="outlined"
              value={value}
              required={required}
              helperText={helperMessage}
              disabled={disabled}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  color: setLabelStyle(error),
                },
              }}
              InputProps={{
                readOnly: readOnly,
                inputProps: {
                  max: 100, min: 0
               },
                endAdornment:
                type !== 'number' ?
                  !readOnly ? <InputAdornment position="end" disablePointerEvents={false}>
                    {valueLength === 0 ?
                      // 空の時は何も表示しない
                      <></> :
                      error ?
                        // エラーの時
                        <ErrorIcon
                          className={classes.errorIcon}
                        /> :
                        // 正常な文字列の時
                        inputEvent ?
                          // 入力中
                          <HighlightOffIcon
                            className={classes.cancelIcon}
                            onClick={() => clearText()}
                          />
                          :
                          // 入力中でない時
                          <DoneIcon
                            className={classes.doneIcon}
                          />}
                  </InputAdornment> :
                    null : null,
              }}
              inputProps={{ maxLength: maxLength }}
            />
            <HelperWrap>
              {helperText1 ? <StyledFormHelper>{helperText1}</StyledFormHelper> : null}
              {helperText2 ? <StyledFormHelper>{helperText2}</StyledFormHelper> : null}
            </HelperWrap>
          </StyledForm>
        </ThemeProvider>
      </Div>
    </SimpleGrid>
  );
}

const StyledTextField = styled(TextField)`
input:-webkit-autofill {
  // inputがオートコンプリート（自動選択）されたときのフォーム内の色を指定する。デフォでは水色
  /* 色はinput要素の背景色（ここでは#fafafa）に一旦揃える */
  box-shadow: 0 0 0px 999px #fafafa inset;
}
`;

const StyledDoneIcon = styled(DoneIcon)`
position: relative;
top: ${smTop};
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px){
  position: relative;
  top: ${smTop};
}`;

const StyledErrorIcon = styled(ErrorIcon)`
position: relative;
top: ${smTop};
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px){
  position: relative;
  top: ${smTop};
}`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
position: relative;
top: ${smTop};
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px){
  position: relative;
  top: ${smTop};
}`;

const StyledForm = styled.div`
margin:${(props) =>
    props.theme.spacing(2)}px 0;
  width:100%
`

/*const Field = styled(TextField)`
    margin:${(props) =>
    props.theme.spacing(2)}px;
    width:calc(100% - ${(props) =>
    props.theme.spacing(0)}px * 2);
    @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px){
        width:375px;
    }
`*/

const Div = styled.div`
    flex-wrap: wrap;
    width: calc(100% - ${(props) =>
    props.theme.spacing(1)}px);
`

const HelperWrap = styled.div`
display: flex;
flex-wrap: wrap;
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
}
`;

const StyledFormHelper = styled(FormHelperText)`
`;

export const CircledAvatar = styled(Avatar)`
width: 24px;
height: 24px;
margin: ${(props) =>
    props.theme.spacing(2)}px ${(props) =>
      props.theme.spacing(1)}px  ${(props) =>
        props.theme.spacing(1)}px ${(props) =>
          props.theme.spacing(1)}px;
@media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px){
  width: 32px;
  height: 32px
}
`;

const Title = styled(Typography)`
    margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left:${props => props.theme.spacing(2)}px;
          font-size:14px;
          color:red;
        }
    }
`
